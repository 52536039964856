import React from "react"
import { Link } from "gatsby"

const Sidebar = ({ job }) => {
  return (
    <div className="services-details-info">

      <div className="services-contact-info">
        <h3 style={{ borderBottom: "1px solid #eeeeee" }}>Infoboard</h3>

        <ul>
          <li>
            <div>Kontakt:</div>
            <div>{job.frontmatter.contactname}</div>
            <div className={"d-flex flex-row mt-2 align-items-center"}><i className="bx bx-phone mr-3" />
              <a href={"tel:"+job.frontmatter.contactphone}>{job.frontmatter.contactphone}</a>
            </div>
            <div className={"d-flex flex-row align-items-center"}><i className="bx bx-envelope mr-3" />
              <a href={"mailto:"+job.frontmatter.contactemail}>{job.frontmatter.contactemail}</a>
            </div>
          </li>
          <li style={{ fontWeight: 300 }}>
            <p>
              Wir mögen keine CV-Submission Portale wo man erst zwei Stunden seine Daten eingeben muss, um dann
              der Firma etwas Mühe zu ersparen.
            </p>
            <p>Nimm einfach die Kommunikationsmethode, die Dir am besten gefällt.
              Und wenn Du erstmal nur "Hallo" sagen willst, ohne irgendwelche Dokumente zu schicken, dann ist das auch
              "ok".
            </p>
          </li>
          <li>
            <Link className="btn btn-sm btn-outline-primary btn-lg text-primary" to={"/jobs/"}
                  role="button">&lt;&lt; Zurück</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
